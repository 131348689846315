import React, { useState } from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layouts/OnboardingLayout.component'
import Header from '../components/header/Header.component'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { useAppContext } from '../context/AppContext'
import { goToIngresar } from '../api/navigation'
import RegisterFormParticularContainer from '../containers/register-particular-form/RegisterFormParticular.container'

const LOGIN_TEXT_PARTICULAR = 'Ingresar a mi cuenta'

const useStyles = makeStyles((theme) => ({
  container: ({ showActivationForm, showLoginLabel }) => {
    const displayButton = showActivationForm || showLoginLabel
    return {
      background: theme.palette.grayScale.g0,
      paddingTop: theme.spacing(6),
      width: '100%',
      height: '100%',
      '& .MuiToolbar-root': {
        '& button': {
          display: displayButton ? 'none' : 'block',
        },
        '& .MuiGrid-root': {
          transform: 'initial',
          margin: '0 auto',
        },
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(8),
      }
    }
  },
}))

const PAGE_TITLE = 'Crear nueva cuenta - Carmuv'
const META_DESCRIPTION =
  'Creá una cuenta en Carmuv para cotizar, comparar y acceder a la mejor financiación. El proceso es simple, rápido y seguro. ¡Registrate ahora!'

const RegisterFormPage = () => {
  const { user } = useAppContext()
  const { showLoginLabel } = user

  const [showActivationForm, setShowActivationForm] = useState(false)

  const classes = useStyles({ showActivationForm, showLoginLabel })
  return (
    <>
      <Layout title={PAGE_TITLE} description={META_DESCRIPTION}>
        <Box className={classes.container}>
          <Header
            showMobileBackIcon
            styleButtonBlack
            onGoBack={goToIngresar}
            menuLinks={[]}
            isLoggedIn={false}
            loginText={showLoginLabel ? ' ' : LOGIN_TEXT_PARTICULAR}
          />
          <Router basepath="/registro-particular">
            <RegisterFormParticularContainer
              showActivationForm={showActivationForm}
              setShowActivationForm={setShowActivationForm}
              path="/"
            />
          </Router>
        </Box>
      </Layout>
    </>
  )
}

export default RegisterFormPage
