import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks'
import { SEND_REGISTRATION_USER } from '../../mutations/sendRegistrationUser'
import {
  validateEmail,
  validateMinLength,
  validatePassword,
  validateRepeatPassword,
  longerPasswordRegex,
} from '../../utils/validators.utils'
import RegisterFormParticular from '../../components/register-particular/RegisterFormParticular.component'
import LoadingModal from '../../components/common/LoadingModal.component'
import { Box } from '@material-ui/core'
import InvalidEmailOptions from '../../components/register-form/InvalidEmailOptions'
import { useAppContext } from '../../context/AppContext'
import setShowLoginLabel from '../../context/user/actions/setShowLoginLabel'
import { TITLE_REGISTER } from '../../components/register-particular/ActivationForm.component'
import ActivationFormContainer from './ActivationForm.container'

const MESSAGE_MODAL = '¡Ya casi, no te vayas!'
const ERROR_MESSAGE_MODAL = '¡Algo pasó!'
const ERROR_LEYEND_SECONDARY_MODAL = 'Los datos no llegaron pero podés volver a intentarlo.'
const BTN_TEXT_MODAL = 'Volver a intentar'
const MESSAGE_MODAL_SECONDARY = 'Estamos guardando tus datos.'

const passwordRegex = RegExp(/((?=.*\d)(?=.*[A-Z])(?=.*\W{0,}).{8,20})/)

const ErrorMessage = ({ text }) => (
  <Box ml="10px" fontSize="11px">
    {text}
  </Box>
)

const RegisterFormParticularContainer = ({ showActivationForm = false, setShowActivationForm = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [goBackClicked, setGoBackClicked] = useState(false)

  const { userDispatch } = useAppContext()

  const validateFields = (fields) => {
    const isValidName = validateMinLength(
      fields.firstname,
      2,
      (minLength) => <ErrorMessage text={`Ingresá al menos ${minLength} caracteres.`} />,
      <ErrorMessage text="Necesitamos tu nombre para crear tu cuenta." />
    )
    const isValidLastName = validateMinLength(
      fields.lastname,
      2,
      (minLength) => <ErrorMessage text={`Ingresá al menos ${minLength} caracteres.`} />,
      <ErrorMessage text="Necesitamos tu apellido para crear tu cuenta." />
    )
    const isValidPhone = validateMinLength(
      fields.phoneNumber,
      10,
      (minLength) => <ErrorMessage text={`Tiene que tener ${minLength} caracteres.`} />,
      <ErrorMessage text="Necesitamos tu teléfono para crear tu cuenta." />
    )
    const isValidEmail = validateEmail(
      fields.email,
      <ErrorMessage text="El e-mail no es válido. Intentá de nuevo." />,
      <ErrorMessage text="Necesitamos tu e-mail para crear tu cuenta." />
    )
    const isValidPassword = validatePassword(
      fields.password,
      <ErrorMessage text="Tu contraseña tiene que tener al menos: 8 caracteres, 1 mayúscula, 1 minúscula y 1 número." />,
      <ErrorMessage text="Tu contraseña tiene que tener al menos: 8 caracteres, 1 mayúscula, 1 minúscula y 1 número." />,
      longerPasswordRegex
    )
    const isValidConfirmPassword = validateRepeatPassword(
      fields.password,
      fields.confirmPassword,
      <ErrorMessage text="Las contraseñas no coinciden. Intentá de nuevo." />,
      <ErrorMessage text="Por favor, repetí tu contraseña." />
    )

    const valid =
      isValidName.valid &&
      isValidLastName.valid &&
      isValidPhone.valid &&
      isValidEmail.valid &&
      isValidPassword.valid &&
      isValidConfirmPassword.valid

    const errors = {
      firstname: isValidName,
      lastname: isValidLastName,
      email: isValidEmail,
      phoneNumber: isValidPhone,
      password: isValidPassword,
      confirmPassword: isValidConfirmPassword,
    }
    return { valid, errors }
  }

  const [sendRegistrationUser, { data: registerResponse, loading: registerLoading }] = useMutation(
    SEND_REGISTRATION_USER,
    {
      onError: () => {
        setModalOpen(true)
      },
      onCompleted: ({ registerUser }) => {
        setModalOpen(false)
        registerUser.success && setShowActivationForm(true)
      },
    }
  )

  const register = (user) => {
    setModalOpen(true)
    sendRegistrationUser({ variables: { user } })
    setShowLoginLabel(userDispatch, {
      showLoginLabel: true,
    })
  }

  const isEmailRepeated = !goBackClicked && registerResponse && !registerResponse?.registerUser?.success

  return (
    <>
      {
        isEmailRepeated 
        ? <InvalidEmailOptions setGoBackClicked={setGoBackClicked} />
        : <Box>
            <LoadingModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              handleCloseModalClick={() => setModalOpen(false)}
              updatedSuccess={Boolean(registerResponse?.registerUser?.success)}
              updating={registerLoading}
              customVisibility={true}
              message={MESSAGE_MODAL}
              leyend={MESSAGE_MODAL_SECONDARY}
              errorMessage={ERROR_MESSAGE_MODAL}
              errorLeyendSecondary={ERROR_LEYEND_SECONDARY_MODAL}
              btnTxt={BTN_TEXT_MODAL}
            />
            {showActivationForm 
              ? <ActivationFormContainer title={TITLE_REGISTER} />
              : <RegisterFormParticular validateFields={validateFields} register={register} goBackClicked={goBackClicked} />
            }
          </Box>
      }
    </>
  )
}

RegisterFormParticularContainer.propTypes = {
  showActivationForm: PropTypes.bool,
  setShowActivationForm: PropTypes.func
}

export default RegisterFormParticularContainer
