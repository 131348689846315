import React, { useState, useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import TextField from '../common/TextField.component'
import MaskedTextField from '../common/MaskedTextField.component'
import clsx from 'clsx'
import Button from '../common/button.component'
import PropTypes from 'prop-types'
import TermsAndConditions from '../common/TermsAndConditions.component'
import PasswordTextField from '../common/PasswordTextField.component'
import { useAppContext } from '../../context/AppContext'
import setRegisterData from '../../context/user/actions/setRegisterData.js';

const useStyle = makeStyles((theme) => ({
  textInput: {
    width: '100%',
    marginTop: '2rem',
    '& input': {
      padding: '.9688rem 1.5rem',
    },
    '& label': {
      paddingLeft: '1rem',
      color: `${theme.palette.grayScale.g400} !important`,
      transform: 'translate(.438rem, 1rem) scale(1)',
    },
    '& fieldset': {
      borderColor: theme.palette.grayScale.g300,
    },
  },
  button: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(6)
    },
  },
}))

const PHONE_MASK = new Array(10).fill(/\d/)
const TEXT_MASK = new Array(99).fill(/[a-zA-ZÀ-ÿ' ]/)
const PHONE_HELPER = (
  <Box fontSize="11px" ml="0.625rem">
    Ingresá tu número sin el 0 y sin el 15.
  </Box>
)
const PASSWORD_HELPER = (
  <Box width="100%" fontSize="11px" ml="0.625rem">
    Tu contraseña tiene que tener al menos: 8 caracteres, 1 mayúscula, 1 minúscula y 1 número.
  </Box>
)

const FormParticular = ({ onSubmit, errorMessages, validateForm, initialFields, goBackClicked }) => {
  const classes = useStyle()
  const { userDispatch } = useAppContext()
  const [formSubmited, setFormSubmited] = useState(false)
  const [fields, setFields] = useState(initialFields)
  const [checked, setChecked] = useState(false)

  const handleTextFieldChange = (key, value) => {
    const newFields = { ...fields }
    newFields[key] = value
    setFields(newFields)
    formSubmited && validateForm(newFields)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const isValid = validateForm(fields)
    setRegisterData(userDispatch, {
      email: fields.email,
      password: fields.password
    })
    onSubmit(fields, isValid)
    setFormSubmited(true)
  }

  const cleanForm = () => {
    setFormSubmited(false)
    setFields({ ...fields, email: '', password: '', confirmPassword: '' })
  }

  useEffect(() => {
    if (goBackClicked) {
      cleanForm()
    }
  }, [goBackClicked])

  return (
    <Box className={classes.registerForm}>
      <MaskedTextField
        error={errorMessages && !errorMessages.firstname.valid}
        helperText={errorMessages && errorMessages.firstname.error}
        className={clsx(classes.textInput)}
        label="Nombre"
        value={fields.firstname}
        onChange={(e) => handleTextFieldChange('firstname', e.target.value)}
        mask={TEXT_MASK}
        variant="outlined"
      />
      <MaskedTextField
        error={errorMessages && !errorMessages.lastname.valid}
        helperText={errorMessages && errorMessages.lastname.error}
        className={clsx(classes.textInput)}
        label="Apellido"
        value={fields.lastname}
        onChange={(e) => handleTextFieldChange('lastname', e.target.value)}
        mask={TEXT_MASK}
        variant="outlined"
      />
      <MaskedTextField
        error={errorMessages && !errorMessages.phoneNumber.valid}
        helperText={errorMessages?.phoneNumber?.error ? errorMessages.phoneNumber.error : PHONE_HELPER}
        className={classes.textInput}
        label="Teléfono"
        placeholder="Ej.: 3511234567"
        value={fields.phoneNumber}
        onChange={(e) => handleTextFieldChange('phoneNumber', e.target.value)}
        mask={PHONE_MASK}
        variant="outlined"
        type="tel"
      />
      <TextField
        error={errorMessages && !errorMessages.email.valid}
        helperText={errorMessages && errorMessages.email.error}
        className={classes.textInput}
        label="E-mail"
        value={fields.email}
        onChange={(e) => handleTextFieldChange('email', e.target.value)}
        variant="outlined"
        inputProps={{
          maxLength: '60'
        }}
      />
      <PasswordTextField
        error={errorMessages && !errorMessages.password.valid}
        helperText={errorMessages?.password?.error ? errorMessages.password.error : PASSWORD_HELPER}
        className={classes.textInput}
        label="Contraseña"
        value={fields.password}
        onChange={(e) => handleTextFieldChange('password', e.target.value)}
        variant="outlined"
        autoComplete="false"
      />
      <PasswordTextField
        error={errorMessages && !errorMessages.confirmPassword.valid}
        helperText={errorMessages && errorMessages.confirmPassword.error}
        className={classes.textInput}
        label="Repetir la contraseña"
        value={fields.confirmPassword}
        onChange={(e) => handleTextFieldChange('confirmPassword', e.target.value)}
        variant="outlined"
        autoComplete="false"
      />
      <Box m="2.5rem 0 3.5rem 0">
        <TermsAndConditions termsAccepted={checked} setTermsAccepted={setChecked} />
      </Box>
      <Button
        primary
        onClick={handleSubmit}
        data-test-id="input-register-btn"
        className={classes.button}
        type="submit"
        fullWidth
        disabled={!checked}
      >
        Continuar
      </Button>
    </Box>
  )
}

FormParticular.propTypes = {
  onSubmit: PropTypes.func,
  errorMessages: PropTypes.object,
  validateForm: PropTypes.func,
  initialFields: PropTypes.object,
}

FormParticular.defaultProps = {
  onSubmit: () => {},
  errorMessages: {},
  validateForm: () => {},
  initialFields: {},
}

export default FormParticular
