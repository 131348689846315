import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import FormParticular from './FormParticular.component'
import Text from '../common/text.component'
import PropTypes from 'prop-types'

const TITLE_REGISTER = 'Crear mi cuenta'
const SUBTITLE_REGISTER =
  'Completá tus datos y seguí los pasos para activarla. La información que nos compartas es confidencial.'

const FIELDS_INITIAL_VALUE = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
}

const useStyle = makeStyles((theme) => ({
  container: {
    width: '25rem',
    maxHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: '1.25rem 1.125rem 0 1.125rem',
    },
  },
  title: {
    marginBottom: '0.75rem',
  },
}))

const RegisterFormParticular = ({
  validateFields = () => {},
  register = () => {},
  formFields = FIELDS_INITIAL_VALUE,
  goBackClicked = false
}) => {
  const classes = useStyle()
  const [errorMessages, setErrorMessages] = useState(null)

  const handleOnSubmit = (fields, isValid) => {
    if (isValid) {
      const { confirmPassword, ...registerFields } = fields
      register(registerFields)
    }
  }

  const validateForm = (fields) => {
    const { valid, errors } = validateFields(fields)
    setErrorMessages(errors)
    return valid
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box className={classes.container}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Text className={classes.title} large semibolder>
            {TITLE_REGISTER}
          </Text>
          <Text little g600 center>
            {SUBTITLE_REGISTER}
          </Text>
        </Box>
        <FormParticular
          onSubmit={handleOnSubmit}
          validateForm={validateForm}
          errorMessages={errorMessages}
          initialFields={formFields}
          goBackClicked={goBackClicked}
        />
      </Box>
    </Box>
  )
}

RegisterFormParticular.propTypes = {
  validateFields: PropTypes.func,
  register: PropTypes.func,
  formFields: PropTypes.object,
  goBackClicked: PropTypes.bool,
}

export default RegisterFormParticular
