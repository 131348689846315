import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Text from '../common/text.component'
import Button from '../common/button.component'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { goToIngresar } from '../../api/navigation'
import { useAppContext } from '../../context/AppContext'
import setShowLoginLabel from '../../context/user/actions/setShowLoginLabel'

const TITLE_TEXT = 'Parece que esta cuenta ya existe'
const SUB_TITLE_TEXT = 'Podés crear una nueva con otro e-mail o probar de ingresar de nuevo.'
const NEW_ACCOUNT_TEXT_BTN = 'Crear cuenta nueva'
const LOGIN_TEXT_BTN = 'Ingresar a mi cuenta'

const useStyle = makeStyles((theme) => ({
  container:{
    display: 'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    }
  },
  title: {
    margin: '1rem 0 0.85rem 0',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: '1rem 5rem 0.85rem 5rem',
    },
  },

  subtitle: {
    width: '60%',
    [theme.breakpoints.down('sm')]:{
      width: '80%',
    },
    textAlign: 'center',
    padding: '0 1rem 4rem',
  },

  btnNewAccount: {
    marginBottom: '0.5rem',
  },

  btnLogin: {
    width: '14.375rem',
    fontSize: '16px',
    fontWeight: '600',
  },

}))

const InvalidEmailOptions = ({ setGoBackClicked }) => {
  const classes = useStyle()

  const { userDispatch } = useAppContext()

  const handleClick = () => {
    setShowLoginLabel(userDispatch, {
      showLoginLabel: false,
    })
    setGoBackClicked(true)
  }

  return (
    <Box className={classes.container}>
      <Text large semibolder className={classes.title}>
        {TITLE_TEXT}
      </Text>
      <Text little className={classes.subtitle}>
        {SUB_TITLE_TEXT}
      </Text>
      <Button onClick={handleClick} primary className={clsx(classes.btnLogin, classes.btnNewAccount)}>
        {NEW_ACCOUNT_TEXT_BTN}
      </Button>
      <Button onClick={goToIngresar} outline className={classes.btnLogin}>
        {LOGIN_TEXT_BTN}
      </Button>
    </Box>
  )
}

export default InvalidEmailOptions
